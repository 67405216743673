/*
Bunt VR
*/

import * as React from "react";
import { graphql } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

//Components
import Layout from "../../components/layout";
import {
  abschnitt as Abschnitt,
  trenner as Trenner,
} from "../../components/layoutComponents";
import Header from "../../components/header/header";
import ProjectHeader from "../../components/projects/ProjectHeader";
import ContentBlock1S from "../../components/projects/ContentBlock1S";
import ProjectFooter from "../../components/projects/ProjectFooter";
import Reihe from "../../components/generics/reihe";
import SpalteLinks from "../../components/generics/spalte_links";
import SpalteRechts from "../../components/generics/spalte_rechts";

import FlyingContactClick from "../../components/contactFlyingButton_click.js";
import SEO from "../../components/generics/seo";
import HR from "./../../components/generics/HR";

//CSS
import "../../components/contactFlyingButton.css";
import "../../components/teaser/teaser.css";

//Videos
import { buntvr_trailer, buntvr_generativeart } from "../../ressources/BuntVR";

//Images
import {
  buntvr_teaser,
  buntvr_exhibition_1,
  buntvr_exhibition_2,
  buntvr_exhibition_3,
  buntvr_exhibition_4,
  buntvr_scan_1,
  buntvr_scan_2,
  buntvr_scan_3,
  buntvr_scan_4,
  buntvr_comparison_1,
  buntvr_comparison_2,
  buntvr_screenshot_1,
  buntvr_screenshot_2,
  buntvr_screenshot_3,
  buntvr_screenshot_4,
} from "../../ressources/BuntVR";

const BuntVR = (location) => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation("buntvr");
  const { language } = useI18next();

  return (
    <>
      {/* Schreibt SEO Optimierungen für die Seite (Titel, Canonical-Link, Beschreibung */}
      {/* LD/JSON bei Bedarf: Product oder ContactPoint von schema.org */}
      <SEO
        lang={language}
        title={t("meta title")}
        canonical={t("meta canonical")}
        description={t("meta description")}
        productLDJSON={true}
      />

      <Layout>
        <Header location={location} />
        {breakpoints.md ? <></> : <Trenner verysmall={true} />}

        <Abschnitt>
          {/* Direkt die übersetzten Texte nutzen */}
          {/* Für HTML Inhalte des Langtextes html={} nutzen, ohne Formatierungen das text={} Attribut */}
          {/* Für ein Video muss videoHeader={true} sein, dann die nötigen Attribute*/}
          {/* Für ein Image muss imageHeader={true} sein, dann die nötigen Attribute*/}
          <ProjectHeader
            titel={t("product titel")}
            untertitel={t("product caption")}
            imageHeader={true}
            imageTeaser={buntvr_teaser}
            hashtags={[
              { text: "AR" },
              { text: "HMI" },
              { text: "interior" },
              { text: "exterior" },
              { text: "metaverse" },
              { text: "multiplatform" },
              { text: "movingplatform" },
              { text: "hololens" },
              { text: "digitaltwin" },
              { text: "case" },
            ]}
            html={t("product description")}
          />
        </Abschnitt>

        {/* Trenner für Abstande oben/unten */}
        <Trenner small={true} />
        {/* HR = Horizontal line, full width */}
        <HR />
        <Trenner small={true} />

        <Abschnitt>    
          {/* Info Eins       */}
          <ContentBlock1S
            // headline={t('info eins titel')}
            video={buntvr_trailer}
            unterschrift={t("info eins untertitel")}
            html={t("info eins")}
          />
          <Trenner small={true} />

          {/* Info Zwei       */}
          <ContentBlock1S image={buntvr_exhibition_1} />
          <ContentBlock1S image={buntvr_exhibition_2} />
          <ContentBlock1S image={buntvr_exhibition_3} />
          <ContentBlock1S
            image={buntvr_exhibition_4}
            unterschrift={t("info zwei untertitel")}
            html={t("info zwei")}
          />
          <Trenner small={true} />

          {/* Info Drei       */}
          <ContentBlock1S image={buntvr_scan_1} />
          <ContentBlock1S image={buntvr_scan_2} />
          <ContentBlock1S image={buntvr_scan_3} />
          <ContentBlock1S
            image={buntvr_scan_4}
            unterschrift={t("info drei untertitel")}
            html={t("info drei")}
          />
          <Trenner small={true} />

          {/* Info Vier       */}
          <ContentBlock1S
            image={buntvr_comparison_1}
            unterschrift={t("info vier untertitel eins")}
          />
          <ContentBlock1S
            image={buntvr_comparison_2}
            unterschrift={t("info vier untertitel zwei")}
            html={t("info vier")}
          />
          <Trenner small={true} />

          {/* Info Fuenf       */}
          <ContentBlock1S
            video={buntvr_generativeart}
            unterschrift={t("info fuenf untertitel")}
            html={t("info fuenf")}  
          />
          <Trenner small={true} />

          {/* Info Sechs       */}
            <ContentBlock1S
            image={buntvr_screenshot_1}
            unterschrift={t("info sechs untertitel")}
          />
          <Trenner small={true} />


        </Abschnitt>

        <Trenner small={true} />

        <Abschnitt line={true} headline={t("projektrahmen headline")}>
          {/* ProjectFooter für weitere Infos zum Projekt. text und html werden als erstes angezeigt, dann alle children */}
          {/* Mit <Reihe> und <SpalteLinks> (rechts) wird die ursprüngliche Aufteilung imitiert */}
          <ProjectFooter text={undefined} html={undefined}>
            <Reihe>
              <SpalteLinks headline={t("entstehung")} />       
              <SpalteRechts content={t("entstehung_content")} />
            </Reihe>

            <Reihe>
              <SpalteLinks headline={t("projektrahmen")} />       
              <SpalteRechts content={t("projektrahmen_content")} />
            </Reihe>

          </ProjectFooter>
        </Abschnitt>

        <FlyingContactClick />
      </Layout>
    </>
  );
};

export default BuntVR;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
